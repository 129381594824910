// PersonalPage.js
import Accordion from "./../Accordion/Accordion";

const PersonalPage = () => {
  return (
    <div>
      <h1> Your Personal Page </h1>
      <Accordion title="Section 1">
        <p> Content for section 1 goes here. </p>
      </Accordion>
      <Accordion title="Section 2">
        <p> Content for section 2 goes here. </p>
      </Accordion>
      {/* Add more accordions as needed */}
    </div>
  );
};

export default PersonalPage;
