import './App.css';
import PersonalPage  from './components/PersonalPage/PersonalPage';
function App() {
    return ( 
    <div className = "" >
        <header className = "" >
        </header> 
        <body>
            <PersonalPage/>
        </body>
    </div>
    );
}

export default App;